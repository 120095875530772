<template>
  <b-card-code>
    <div v-if="$can('create','Assigned Fee')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add New') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="assigned_student_fees"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>

         <span v-else-if="props.column.field === 'month_year'">
          {{ getMonthName(props.row.month_year) }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Assigned Fee')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('update','Assigned Fee')" @click="selectedRow(props.row)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="props.row.student_payments_count<=0 && $can('remove','Assigned Fee')" variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer size="lg"
        :title="model_mode==='add'?'Assign new student fee':'Assigned fee edit'"
    >
      <!-- form -->
      <validation-observer action="javascript:void(0)" ref="simpleRules">
        <form ref="assign_student_fee">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="class"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Versions </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="version"
                  rules="required"
              >
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Wing </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="wing"
                  rules="required"
              >
                <select name="wing_id" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Group </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="student group"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">{{sgrp.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Fee Type </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="student fee type"
                  rules="required"
              >
                <select name="student_fee_type_id" v-model="student_fee_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(studentFee, key) in active_student_fee_types" :value="studentFee.id" :key="key">{{studentFee.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Academic Year </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="academic year"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(aYear, key) in active_academic_years" :value="aYear.id" :key="key">{{aYear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Payment Month </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="student payment month year"
                    rules="required"
                >
                  
                 <select name="month_year" v-model="month_year" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                 <option :value="null">Select One</option>
                 <option  :value="1">January</option>
                 <option  :value="2">February</option>
                 <option  :value="3">March</option>
                 <option  :value="4">April</option>
                 <option  :value="5">May</option>
                 <option  :value="6">June</option>
                 <option  :value="7">July</option>
                 <option  :value="8">August</option>
                 <option  :value="9">September</option>
                 <option  :value="10">October</option>
                 <option  :value="11">November</option>
                 <option  :value="12">December</option>
                </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Fee amount </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="student fee amount"
                    rules="required"
                >
                  <b-form-input
                      v-model.number="amount" type="number"
                      name="amount" placeholder="fee amount"
                      :state="errors.length > 0 ? false:null"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Late fee amount </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="student late fee amount"
                    rules="required"
                >
                  <b-form-input
                      v-model.number="late_fee" type="number"
                      name="late_fee" placeholder="late fee amount"
                      :state="errors.length > 0 ? false:null"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Payment Last Date </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="payment last date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="payment_last_date"
                      name="payment_last_date" placeholder="payment last date"
                      :state="errors.length > 0 ? false:null"></b-form-datepicker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col>
              <b-button class="mt-2"
                  variant="primary"
                  type="submit"
                  @click.prevent="submitFeeInformation"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BRow, BCol,BCardText,BFormDatepicker,BBadge,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'AssignStudentFee',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,VueJsonToCsv,
    BFormGroup,
    BFormInput,
    BFormSelect,BFormCheckbox,
    BButton,BFormDatepicker,
    BModal,BBadge,
    BRow, BCol,
    ValidationProvider,
    ValidationObserver,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Version',
          field: 'version.name',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Wing',
          field: 'wing.name',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Fee Type',
          field: 'student_fee_type.name',
        },
        {
          label: 'Academic Year',
          field: 'academic_year.year',
        },
        {
          label: 'Payment Month',
          field: 'month_year',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Payment Last Date',
          field: 'payment_last_date',
        },
        {
          label: 'Late Fee',
          field: 'late_fee',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      classes:[],
      versions:[],
      student_groups:[],
      wings:[],
      classes_id:null,
      version_id:null,
      wing_id:null,
      student_group_id:null,
      student_fee_type_id:null,
      academic_year_id:null,
      month_year:null,
      amount:0,
      late_fee:0,
      payment_last_date:'',
      selected_id:null,
      columns10: [
        {
          label: 'Version',
          field: 'Version',
        },
        {
          label: 'Class',
          field: 'Classes',
        },
        {
          label: 'Wing',
          field: 'Wing',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Fee Type',
          field: 'FeeType',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
        {
          label: 'Payment Month',
          field: 'PaymentMonth',
        },
        {
          label: 'Amount',
          field: 'Amount',
        },
        {
          label: 'Payment Last Date',
          field: 'PaymentLastDate',
        },
        {
          label: 'Late Fee',
          field: 'LateFee',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],

    }
  },
  created() {
    this.$store.dispatch('GET_ALL_ASSIGNED_STUDENT_FEE');
    this.$store.dispatch('GET_ALL_STUDENT_FEE_TYPE');
    this.$store.dispatch('GET_ACTIVE_ACADEMIC_YEAR');
    this.getAll();
  },
  methods:{
    getMonthName(month_id){
      var month_name = '';
      if(parseInt(month_id) === 1){
        month_name = 'January';
      }
      else if(parseInt(month_id) === 2){
        month_name = 'February';
      }
      else if(parseInt(month_id) === 3){
        month_name = 'March';
      }
      else if(parseInt(month_id) === 4){
        month_name = 'April';
      }
      else if(parseInt(month_id) === 5){
        month_name = 'May';
      }
      else if(parseInt(month_id) === 6){
        month_name = 'June';
      }
      else if(parseInt(month_id) === 7){
        month_name = 'July';
      }
      else if(parseInt(month_id) === 8){
        month_name = 'August';
      }
      else if(parseInt(month_id) === 9){
        month_name = 'September';
      }
      else if(parseInt(month_id) === 10){
        month_name = 'October';
      }
      else if(parseInt(month_id) === 11){
        month_name = 'November';
      }
      else if(parseInt(month_id) === 12){
        month_name = 'December';
      }

      return month_name;
    },

    selectedRow(row){
      this.selected_id=row.id;
      this.classes_id=row.classes_id;
      this.version_id=row.version_id;
      this.wing_id=row.wing_id;
      this.student_group_id=row.student_group_id;
      this.student_fee_type_id=row.student_fee_type_id;
      this.academic_year_id=row.academic_year_id;
      this.month_year=row.month_year;
      this.amount=row.amount;
      this.late_fee=row.late_fee;
      this.payment_last_date=row.payment_last_date;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    submitFeeInformation() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.assign_student_fee);
          if (this.model_mode ==='add'){
            apiCall.post('/assign/student/fee/store',data).then((response)=>{
              if (response.data.status ==='success'){
                this.month_year='';
                this.amount=0;
                this.payment_last_date='';
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_ASSIGNED_STUDENT_FEE');
              }else{
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/assign/student/fee/update${this.selected_id}`,data).then((response)=>{
              this.hideModal();
              this.$refs.assign_student_fee.reset();
              this.$refs.simpleRules.reset();
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_ASSIGNED_STUDENT_FEE');
            }).catch((error)=>{
              if (error.response.status == 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },

    statusChange(id){
      apiCall.put(`/assign/student/fee/status/change${id}`).then((response)=>{
        this.$toaster.success(response.data.message);
        this.$store.dispatch('ASSIGNED_FEE_STATUS_CHANGE',id);
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_ASSIGNED_STUDENT_FEE');
      });
    },

    remove(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/assign/student/fee/remove${id}`).then((response)=>{
            if (response.data.status ==='success'){
              this.$toaster.success(response.data.message);
              this.$store.dispatch('REMOVE_ASSIGNED_FEE',id);
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },

    showModal() {
      this.selected_id=null;
      this.classes_id=null;
      this.version_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.student_fee_type_id=null;
      this.academic_year_id=null;
      this.month_year=null;
      this.amount=0;
      this.late_fee=0;
      this.payment_last_date=null;
      this.model_mode='add';
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    /*
    * method for get class realted data
    * */
    getAll(){
      apiCall.get('/get/active/class/with/v/s/sg/wings').then((response)=>{
        this.classes=response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },
    pdfGenerate(){
      let title="Student fee options";
      let clm=['Version','Classes','Wing','StudentGroup','FeeType','AcademicYear','PaymentMonth',
        'Amount','PaymentLastDate','LateFee','Status'];
      let data =new FormData();
      data.append('title',title);
      data.append('columns',JSON.stringify(clm));
      data.append('data',JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
        const content = response.headers['content-type'];
        download(response.data,'fee_assigned_student.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['assigned_student_fees','active_student_fee_types','active_academic_years']),
    getData(){
      return this.assigned_student_fees.map(item => ({
        Version:item.version?item.version.name:'',
        Classes:item.classes?item.classes.name:'',
        Wing:item.wing?item.wing.name:'',
        StudentGroup:item.student_group?item.student_group.name:'',
        FeeType:item.student_fee_type?item.student_fee_type.name:'',
        AcademicYear:item.academic_year?item.academic_year.year:'',
        PaymentMonth:item.month_year,
        Amount:item.amount,
        PaymentLastDate:item.payment_last_date,
        LateFee:item.late_fee,
        Status: item.is_active?'Active':'Inactive',
      }));
    }
  },
  watch:{
    classes_id(){
      let s_classes = this.classes.find(item=>parseInt(item.id)===parseInt(this.classes_id));
      if (this.model_mode !=='edit'){
        this.version_id=null;
        this.wing_id=null;
        this.student_group_id=null;
      }
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.wings = s_classes.wings??[];
        this.student_groups = s_classes.student_groups??[];
      }else {
        this.versions =[];
        this.wings =[];
        this.student_groups =[];
      }
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>